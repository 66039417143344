import { Grid } from "@mui/material";
import {
  FastCheckboxField,
  FastTextField,
  FastSelectFieldSingle,
  FastPhoneField,
  FastSelectField,
  ImageUploadField,
} from "../CustomReactHookFields";
import {
  useGetAccessoriesQuery,
  useGetServicesbyProviderQuery,
} from "features/services/servicesApiSlice";
import { useEffect } from "react";

export const GetFields = ({ fields, disabled = false, control, id, setValue, watch }) => {
  const { data: services, isLoading: isLoadingServices } =
    useGetServicesbyProviderQuery({ id });
  const { data: accessories, isLoading: isLoadingAccessories } =
    useGetAccessoriesQuery({ id });

  const answers = watch(); 

  const filteredFields = fields.filter((item) => {
    const skipLogic = item.skip_logics || [];
    return skipLogic.every((logic) => {
      const answer = answers[logic.target_question_id]; 
      if (logic.condition_type === "WAS_ANSWERED") {
        if (answer === undefined) {
          return false; 
        }
  
        return logic.comparison_type === "EQUALS"
          ? answer === logic.expected_value
          : true;
      }
      return true;
    });
  });

  return (
    <>
      {filteredFields.map((item, index) => {
        const commonProps = {
          key: index,
          label: item.question,
          name: item.id.toString(),
          disabled,
          control,
          required: item?.is_required,
        };

        switch (item.question_type) {
          case "PARAGRAPH_TEXT":
            return (
              <Grid item xs={10} lg={6} key={index}>
                <FastTextField {...commonProps} type="text" />
              </Grid>
            );
            case "NUMBER":
              return (
                <Grid item xs={10} lg={6} key={index}>
                  <FastTextField {...commonProps} type="number" />
                </Grid>
              );
          case "EMAIL":
            return (
              <Grid item xs={10} lg={6} key={index}>
                <FastTextField {...commonProps} type="email" />
              </Grid>
            );
          case "PHONE":
            return (
              <Grid item xs={10} lg={6} mb={2} key={index}>
                <FastPhoneField {...commonProps} />
              </Grid>
            );
          case "MULTIPLE_CHOICE":
            return (
              <Grid item xs={10} lg={6} key={index}>
                <FastSelectField
                  options={item?.choices}
                  {...commonProps}
                />
              </Grid>
            );
          case "SINGLE_CHOICE":
            return (
              <Grid item xs={10} lg={6} key={index}>
                <FastSelectFieldSingle
                  options={item?.choices}
                  {...commonProps}
                />
              </Grid>
            );
          case "LINK":
            return (
              <Grid item xs={10} lg={6} key={index}>
                <FastTextField type="url" {...commonProps} />
              </Grid>
            );
          case "IMAGE_BASE64_HEX":
            return (
              <Grid item xs={10} lg={6} key={index}>
                <ImageUploadField {...commonProps} />
              </Grid>
            );
          case "ADDITIONAL_TERMS_AND_CONDITIONS":
            return (
              <Grid item xs={12} lg={12} key={index}>
                <FastCheckboxField
                  options={[item.question]}
                  {...commonProps}
                />
              </Grid>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
